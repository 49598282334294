import React from "react";

export default ({ color }) => (
  <svg viewBox="0 0 23 32">
    <path
      fill={color}
      d="M11.7,9.2c-0.1,0-0.1,0-0.2,0c-1.6,0-3.2,0.3-4.6,1V6.8c0-2.5,2-4.5,4.6-4.5h0.1c2.5,0,4.6,2,4.6,4.5c0,0.7,0.6,1.1,1.1,1.1
	s1.1-0.6,1.1-1.1c0-3.7-3.1-6.8-6.8-6.8h-0.1C7.6,0,4.6,3.1,4.6,6.8v4.7c-2.7,2.1-4.6,5.3-4.6,9C0,26.9,5.1,32,11.5,32
	C17.9,32,23,26.9,23,20.6C23,14.4,18,9.3,11.7,9.2z M11.5,29.7c-5.1,0-9.2-4.1-9.2-9.2c0-3.2,1.6-5.9,4-7.6c0,0,0.1,0,0.1-0.1
	c0.1-0.1,0.2-0.1,0.3-0.2c0.3-0.2,0.6-0.3,0.9-0.5C7.9,12.1,8.1,12,8.3,12c0.3-0.1,0.6-0.2,0.9-0.3c0.2,0,0.3-0.1,0.6-0.1
	c0.6-0.1,1-0.1,1.6-0.1c5.1,0,9.2,4.1,9.2,9.2C20.7,25.7,16.6,29.7,11.5,29.7z M11.5,16.1c-1.9,0-3.4,1.6-3.4,3.4
	c0,0.9,0.3,1.8,1,2.4c0.3,0.3,0.8,0.7,1.3,0.8v4.7h2.3v-4.7c0.5-0.2,0.9-0.5,1.3-0.8c0.6-0.6,1-1.5,1-2.4
	C15,17.5,13.4,16.1,11.5,16.1z M12.3,20.2c-0.2,0.2-0.5,0.3-0.8,0.3c-0.3,0-0.6-0.1-0.8-0.3c-0.2-0.2-0.3-0.5-0.3-0.8
	c0-0.7,0.5-1.1,1.1-1.1c0.7,0,1.1,0.5,1.1,1.1C12.6,19.8,12.5,20,12.3,20.2z"
    />
  </svg>
);
